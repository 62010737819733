import React from 'react';

const CheckedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.size ?? 37}
    height={props?.size ?? 37}
    fill="none"
    style={props.style}
    viewBox='0 0 37 37'
    {...props}
  >
    <path
      fill={props?.color ?? '#1D6EA3'}
      d="M18.5 0C8.299 0 0 8.299 0 18.5S8.299 37 18.5 37 37 28.701 37 18.5 28.701 0 18.5 0Z"
    />
    <path
      fill="#F2F2F2"
      d="m27.301 14.503-9.95 10.217c-.298.306-.69.46-1.081.46-.392 0-.784-.154-1.082-.46l-4.975-5.109a1.6 1.6 0 0 1 0-2.222 1.5 1.5 0 0 1 2.164 0l3.893 3.997 8.867-9.105a1.5 1.5 0 0 1 2.164 0 1.6 1.6 0 0 1 0 2.222Z"
    />
  </svg>
);

export default CheckedIcon;
