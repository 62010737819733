import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PrescriptionSwitch from './PrescriptionSwitch/PrescriptionSwitch';
import PageContentClinic from '../../components/PageContentClinic/PageContentClinic';

const PrescriptionSwitchNavigationStack = () => {

  return (
    <div>
      <Switch>
        <Route
          path="/"
        >
          <PageContentClinic pageTitle="Avaliação">
            <PrescriptionSwitch />
          </PageContentClinic>
        </Route>
      </Switch>
    </div>
  );
};

export default PrescriptionSwitchNavigationStack;
