import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import packageJson from '../../../package.json';
import PrescriptionNavigationStack from '../pages/Prescription/PrescriptionNavigationStack';
import PrescriptionSwitchNavigationStack from '../pages/PrescriptionSwitch/PrescriptionSwitchNavigationStack';
import PageViewProvider from '../pages/PageViewProvider/PageViewProvider';
import TimerProvider from '../components/TimerPrivider/TimerProvider';

const Router = () => {

  const caching = () => {
    const version = localStorage.getItem('version');

    if (version !== packageJson.version) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          names.forEach(name => {
            caches.delete(name);
          });
        });
        window.location.reload(true);
      }

      localStorage.clear();
      localStorage.setItem('version',packageJson.version);
    }
  };

  useEffect(() => {
    caching();
  },[]);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact
          path="/">
          <TimerProvider expiryTimestamp={10000}/>
          <PageViewProvider />
          <PrescriptionSwitchNavigationStack />
        </Route>
        <Route path="/questionario">
          <PrescriptionNavigationStack />
        </Route>
      </Switch>
    </BrowserRouter>

  );
};

export default Router;

