import React from 'react';

import box from '../../assets/medical/how/box.png';
import cart from '../../assets/medical/how/cart.png';
import phone from '../../assets/medical/how/phone.png';
import { I18n } from 'react-redux-i18n';

const PrescriptionStepper = ({
  startForm,
}) => {

  return (
    <section className="prescription-switch-container">
      <h1 className='prescription-switch-container__title'>{I18n.t('PAGES.HOME.PRESCRIPTION.TITLE')}</h1>

      <article className='prescription-switch-container__box-content mobile-hidden'>
        <article className='prescription-switch-container__box-content__content-info'>
          <div className='prescription-switch-container__box-content__content-info__circle-content'>
            <img
              src={phone}
              alt="phone"
            />
          </div>
          <h2>{I18n.t('PAGES.HOME.PRESCRIPTION.INITIAL_CONTENT.INSTRUCTIONS.ONE.TITLE')}</h2>
          <p>
            {I18n.t('PAGES.HOME.PRESCRIPTION.INITIAL_CONTENT.INSTRUCTIONS.ONE.DESCRIPTION')}
          </p>
        </article>

        <article className='prescription-switch-container__box-content__content-info'>
          <div className='prescription-switch-container__box-content__content-info__circle-content'>
            <img
              src={cart}
              alt="cart"
            />
          </div>
          <h2>{I18n.t('PAGES.HOME.PRESCRIPTION.INITIAL_CONTENT.INSTRUCTIONS.TWO.TITLE')}</h2>
          <p>
            {I18n.t('PAGES.HOME.PRESCRIPTION.INITIAL_CONTENT.INSTRUCTIONS.TWO.DESCRIPTION')}
          </p>
        </article>

        <article className='prescription-switch-container__box-content__content-info'>
          <div className='prescription-switch-container__box-content__content-info__circle-content'>
            <img
              src={box}
              alt="box"
            />
          </div>
          <h2>{I18n.t('PAGES.HOME.PRESCRIPTION.INITIAL_CONTENT.INSTRUCTIONS.THREE.TITLE')}</h2>
          <p>
            {I18n.t('PAGES.HOME.PRESCRIPTION.INITIAL_CONTENT.INSTRUCTIONS.THREE.DESCRIPTION')}
          </p>
        </article>
      </article>

      <div className="prescription-switch__container__step-two">
        <div className="prescription-switch__container__step-two__container">
          <div className="prescription-switch__container__step-two__container__item mobile-visible">
            <div className="prescription-switch__container__step-two__container__item__img-container">
              <img
                src={phone}
                className="prescription-switch__container__step-two__container__item__img"
                alt="phone"
              />
            </div>
            <div className="prescription-switch__container__step-two__container__item__desc mobile-visible">
              {I18n.t('PAGES.HOME.PRESCRIPTION.INITIAL_CONTENT.INSTRUCTIONS.ONE.DESCRIPTION_MOBILE.TITLE')}
              <br />
              {I18n.t('PAGES.HOME.PRESCRIPTION.INITIAL_CONTENT.INSTRUCTIONS.ONE.DESCRIPTION_MOBILE.DESCRIPTION')}
            </div>
            <div className="prescription-switch__container__step-two__container__item__img-container">
              <img
                src={cart}
                className="prescription-switch__container__step-two__container__item__img"
                alt="cart"
              />
            </div>
            <div className="prescription-switch__container__step-two__container__item__title">
              {I18n.t('PAGES.HOME.PRESCRIPTION.INITIAL_CONTENT.INSTRUCTIONS.ONE.TITLE')}
            </div>
            <div className="prescription-switch__container__step-two__container__item__desc mobile-visible">
              {I18n.t('PAGES.HOME.PRESCRIPTION.INITIAL_CONTENT.INSTRUCTIONS.TWO.DESCRIPTION_MOBILE.DESCRIPTION')}
            </div>
          </div>
          <div className="prescription-switch__container__step-two__container__item mobile-visible">
            <div className="prescription-switch__container__step-two__container__item__img-container">
              <img
                src={box}
                className="prescription-switch__container__step-two__container__item__img"
                alt="box"
              />
            </div>
            <div className="prescription-switch__container__step-two__container__item__desc mobile-visible">
              {I18n.t('PAGES.HOME.PRESCRIPTION.INITIAL_CONTENT.INSTRUCTIONS.THREE.DESCRIPTION_MOBILE')}<strong>estorno integral</strong> do
              valor pago.
            </div>
          </div>
        </div>

        <button
          className="prescription-switch__container__step-two__button"
          onClick={startForm}
        >
          {I18n.t('PAGES.HOME.PRESCRIPTION.INITIAL_CONTENT.INSTRUCTIONS.BUTTONS.START')}
        </button>
      </div>
    </section>
  );
};

export default PrescriptionStepper;