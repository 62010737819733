import React from 'react';
import PageHeader from '../../../components/PageHeader/PageHeader';
import CheckoutFooter from '../../../components/CheckoutFooter/CheckoutFooter';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { useTextAndClassname } from '../../../hook/useTextAndClassname';
import { Link } from 'react-router-dom';
import CheckedIcon from '../../../components/Icons/checkedIcon';

const PrescriptionFeedback = ({ prescriptionFeedback }) => {

  const { title, classTitle, colorIcon, url } = useTextAndClassname(prescriptionFeedback);

  return (
    <>
      <PageHeader hideHeaderOptions/>
      <main className='container-feedback'>
        <section className='container-feedback__content'>
          <article className={`container-feedback__content__title ${classTitle} mobile-hidden`}>
            <h1>{title?.toUpperCase()}</h1>
            <CheckedIcon color={colorIcon} />
          </article>
          <article className={`container-feedback__content__title ${classTitle} mobile-visible-prescription-feedback`}>
            <CheckedIcon
              color={colorIcon}
              style={{ width: '28px', height: '28px' }}
              size={28}
            />
            <h1>{title?.toUpperCase()}</h1>
          </article>

          <article className='container-feedback__content__description'>
            <h2>{I18n.t('COMPONENTS.PRESCRIPTION.PRESCRIPTION_FEEDBACK.DESCRIPTION.TITLE')}</h2>

            <p><br />{I18n.t('COMPONENTS.PRESCRIPTION.PRESCRIPTION_FEEDBACK.DESCRIPTION.WAIT_RESULT')}</p>
            <p>{I18n.t('COMPONENTS.PRESCRIPTION.PRESCRIPTION_FEEDBACK.DESCRIPTION.ORIENTATION')}</p>

            <article className='container-feedback__content__description__reserve-button'>
              <a
                href={url}
                target='_blank'
                rel='noopener noreferrer'
              >
                <button>{I18n.t('COMPONENTS.PRESCRIPTION.PRESCRIPTION_FEEDBACK.BUTTONS.RESERVE')}</button>
              </a>
            </article>

          </article>
        </section>
        <section className='container-feedback__content__back-to-home'>
          <Link
            style={{ color: '#474747' }}
            to='/'
          >
            {I18n.t('COMPONENTS.PRESCRIPTION.PRESCRIPTION_FEEDBACK.LINKS.BACK_TO_PRESCRIPTION')}
          </Link>
        </section>
      </main>
      <CheckoutFooter />
    </>
  );
};

const mapStateToProps = (state) => ({
  prescriptionFeedback: state.prescription.prescriptionFeedback,
});

export default connect(mapStateToProps)(PrescriptionFeedback);