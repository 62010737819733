export const SelectableItems = {
  HAIR_LOSS: {
    id: 1,
    title: 'Saúde capilar',
    soldOff: false,
    soldOffTitle: 'Produto Esgotado',
  },
  MELATONIN: {
    id: 2,
    title: 'Saúde do sono',
    soldOff: false,
    soldOffTitle: 'Produto Esgotado',
  },
  ED: {
    id: 3,
    title: 'Saúde sexual',
    soldOff: false,
    soldOffTitle: 'Produto Esgotado',
  },
  SS: {
    id: 3,
    title: 'Saúde sexual',
    description: 'Bem-estar',
    soldOff: false,
    soldOffTitle: 'Produto Esgotado',
  },
  EJ: {
    id: 4,
    title: 'Ejaculação Precoce',
    soldOff: false,
    soldOffTitle: 'Produto Esgotado',
  },
  BE: {
    id: 5,
    title: 'Saúde Sexual',
    soldOff: false,
    soldOffTitle: 'Produto Esgotado',
  },
};