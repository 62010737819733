import React, { useEffect, useState } from 'react';
import { setDataLayer } from '../../../services/analytics';
import { message } from 'antd';
import AdvancedInput from '../../../components/AdvancedInput/AdvancedInput';
import TopBarPrescription from '../../../components/TopBarPrescription/TopBarPrescription';
import { useHistory, useLocation } from 'react-router-dom';
import {
  keepOnlyNumbers,
  removeSpecialChars,
  validaCpf,
  validateBirthDate,
} from '../../../utils/string';
import {
  AuthActions,
  PrescriptionActions,
  UserActions,
} from '../../../redux/actions';
import { connect } from 'react-redux';
import {
  cpfMask,
  dateOfBirthMask,
  onlyNumbers,
  phoneMask,
} from '../../../utils/masks';
import AuthService from '../../../services/auth';
import moment from 'moment';
import { takeOutBlankSpace } from '../../../utils/spaceRegex';
import { get, reset, set } from '../../../services/storage';
import { pixelTrack } from './../../../utils/pixel';
import * as FacebookActions from '../../../redux/actions/facebook';
import facebookUserActivity from '../../../enum/facebookUserActivity';
import { I18n } from 'react-redux-i18n';
import {
  EvaluationQuestions,
  HaveAttentionFollowConsultation,
  HaveNitrateMedication,
} from '../../../utils/localStorageVariables';
import { SelectableItems } from '../../../utils/prescriptionSwitch';
import PrescriptionStepper from '../../../components/PrescriptionStepper/PrescriptionStepper';
import PrescriptionChoice from '../../../components/PrescriptionChoice/PrescriptionChoice';
import RenderCondition from '../../../components/no-visual/renderCondition';
import { PrescriptionStepsEnum } from '../../../enum/prescriptionSteps';

const defaultForm = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmpassword: '',
  grantType: 'password',
  profileType: 1,
  phone: '',
};

const PrescriptionSwitch = ({
  updateUserProfile,
  register,
  login,
  me,
  getMe,
  loading,
  setQuizUserResponse,
  setQuizUserPayload,
  userActivity,
}) => {
  const [ currentSelected, setCurrentSelected ] = useState(null);
  const [ form, setForm ] = useState(defaultForm);
  const [ step, setStep ] = useState(1);
  const [ type, setType ] = useState('');

  const history = useHistory();
  const location = useLocation();
  const regex = /[?&]type=([^&]+)/;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const startIn = searchParams.get('startIn');

    if (Number(startIn) === SelectableItems.MELATONIN.id) {
      choosePrescriptionForm(SelectableItems.MELATONIN.id);
    }
  }, []);

  const currentSelectedTitle = [
    'Você está há poucos passos para ter mais cabelos!',
    'Você está há poucos passos para dormir melhor e mais rápido!',
    'Você está há poucos passos para uma vida sexual mais ativa!',
    'Você está há poucos passos para uma vida sexual mais ativa!',
  ];

  const prescrptionTypeItems = [ 'hair', 'sleep', 'ed', 'ej' ];

  useEffect(() => {
    AuthService.isFromPrescriptionDomain();

    if (AuthService.isAuthenticated()) {
      getMe();
    }

    setQuizUserResponse(null);
    setQuizUserPayload(null);
  }, [ location.pathname ]);

  useEffect(() => {
    const match = location.search.match(regex);

    if (match) {
      setType(match[1]);
      setStep(3);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(EvaluationQuestions, []);
    localStorage.setItem(HaveAttentionFollowConsultation, false);
    localStorage.setItem(HaveNitrateMedication, false);

    if (get('redirectToInitialForm')) {
      reset('redirectToInitialForm');
    }

    getUrlParams();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (me) {
      setForm({
        ...form,
        ...me,
        birthDate: moment(me.birthDate).format('DD/MM/YYYY') || '',
        password: me.document,
      });
    }
  }, [ me ]);

  const getUrlParams = () => {
    const useQuery = new URLSearchParams(window.location.search);
    const sendHome = useQuery.get('sendHome');
    const selectPrescription = parseInt(useQuery.get('fromProduct'));

    if (sendHome) {
      set('sendHome', true);
    }

    if (selectPrescription) {
      choosePrescriptionForm(selectPrescription);
    }
  };

  const goToNextStep = () => {
    getMe();

    if (type) {
      history.push(`/questionario/${type}/passo-1`);
    }

    if (currentSelected === SelectableItems.HAIR_LOSS.id) {
      history.push('/questionario/quedadecabelo/passo-1');
    }

    if (currentSelected === SelectableItems.MELATONIN.id) {
      history.push('/questionario/melatonina/passo-1');
    }

    if (currentSelected === SelectableItems.ED.id) {
      history.push('/questionario/ed/passo-1');
    }

    if (currentSelected === SelectableItems.EJ.id) {
      history.push(`/questionario/${prescrptionTypeItems[3]}/passo-1`);
    }

    if (currentSelected === SelectableItems.BE.id) {
      history.push('/questionario/be/passo-1');
    }
  };

  const handleChangeForm = (name, value) => {
    if (name === 'birthDate' && value.length > 10) {
      return null;
    }

    if (name === 'birthDate') {
      value = dateOfBirthMask(value);
    }

    if (name === 'email') {
      value = takeOutBlankSpace(value);
    }

    setForm({ ...form, [name]: value });
  };

  const onNextClick = async () => {

    if (
      !form.phone ||
      form.phone.includes('99999-9999') ||
      keepOnlyNumbers(form.phone).length < 10
    ) {
      return message.error('Telefone inválido');
    }

    if (!form.name) {
      return message.error('Informe o nome completo.');
    }

    if (!/\s/.test(form.name)) {
      return message.error('Informe também o sobrenome');
    }

    if (!form.email) {
      return message.error('Informe um e-mail válido.');
    }

    if (!form.password) {
      return message.error('Informe um CPF válido.');
    }

    if (!validaCpf(removeSpecialChars(form.password))) {
      return message.error('Informe um CPF válido.');
    }

    if (
      !moment(form.birthDate, 'YYYY-MM-DD').isValid() ||
      !validateBirthDate(form.birthDate)
    ) {
      return message.error('Data de nascimento inválida');
    }

    if (!form.birthDate) {
      return message.error('Uma data de nascimento');
    }

    const payload = {
      name: form.name,
      email: form.email.toLowerCase(),
      birthDate: moment(form.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      phone: onlyNumbers(form.phone),
      password: removeSpecialChars(form.password),
      document: removeSpecialChars(form.password),
      grantType: 'password',
    };

    if (me) {
      pixelTrack(
        'startedPrescription',
        {
          name: me.name,
          email: me.email,
          prescriptionType: prescrptionTypeItems[currentSelected - 1],
        },
        true,
      );
      userActivity(
        {
          source: location.pathname,
        },
        facebookUserActivity.initMedicalAppointment,
      );
      updateUserProfile(me.id, payload, () => {
        setDataLayer('medical_evaluation_started');
        goToNextStep();
      });
    } else {
      await register(payload, async (response) => {
        if (response && response !== 'cpf_already_registered') {
          await login(payload, (loginResponse) => {
            if (!loginResponse.error) {
              try {
                setDataLayer('medical_evaluation_started');
                userActivity(
                  {
                    source: location.pathname,
                  },
                  facebookUserActivity.initMedicalAppointment,
                );
                userActivity(
                  {
                    source: location.pathname,
                  },
                  facebookUserActivity.register,
                );
              } catch (e) {
                goToNextStep();
              } finally {
                goToNextStep();
              }
            } else {
              message.error(
                'Os dados informados não são válidos. Verifique e tente novamente.',
              );
            }
          });
        } else if (response && response === 'cpf_already_registered') {
          message.error(
            'CPF já cadastrado, verifique o e-mail inserido. Em caso de dificuldades, nos chame no WhatsApp.',
          );
        } else {
          message.error(
            'Erro ao realizar cadastro. Tente novamente mais tarde.',
          );
        }
      });
    }
  };

  const choosePrescriptionForm = (currentQuiz, soldOff, soldOffTitle) => {
    if (soldOff) {
      return message.warning(soldOffTitle);
    }

    selectCurrentQuiz(currentQuiz);
    setStep(2);
  };

  const selectCurrentQuiz = (currentQuiz) => {
    setCurrentSelected(currentQuiz);
  };

  const goBack = () => {
    if (step === 2) {
      selectCurrentQuiz(null);
    }

    if (step > 1) {
      setStep(step - 1);
    } else if (step === 1) {
      history.push('/');
    }
  };

  const startForm = () => {
    if (!me) {
      setStep(3);
    } else {
      pixelTrack(
        'startedPrescription',
        {
          name: me.name,
          email: me.email,
          prescriptionType: prescrptionTypeItems[currentSelected - 1],
        },
        true,
      );

      userActivity(
        {
          source: location.pathname,
        },
        facebookUserActivity.initMedicalAppointment,
      );

      if (currentSelected === SelectableItems.HAIR_LOSS.id) {
        history.push('/questionario/quedadecabelo/passo-1');
      }

      if (currentSelected === SelectableItems.MELATONIN.id) {
        history.push('/questionario/melatonina/passo-1');
      }

      if (currentSelected === SelectableItems.ED.id) {
        history.push('/questionario/ed/passo-1');
      }

      if (currentSelected === SelectableItems.EJ.id) {
        history.push(`/questionario/${prescrptionTypeItems[3]}/passo-1`);
      }

      if (currentSelected === SelectableItems.BE.id) {
        history.push('/questionario/be/passo-1');
      }
    }
  };

  return (
    <div className="prescription-switch">
      <div className="prescription-switch__header__bg">
        <TopBarPrescription
          type={currentSelected}
          prevPage={goBack}
          currentPage={step}
        />
        <div className="prescription-switch__container">
          <RenderCondition condition={step === PrescriptionStepsEnum.STEP_ONE}>
            <PrescriptionChoice choosePrescriptionForm={choosePrescriptionForm}/>
          </RenderCondition>
          <RenderCondition condition={step === PrescriptionStepsEnum.STEP_TWO}>
            <PrescriptionStepper startForm={() => startForm()} />
          </RenderCondition>
          {step === 3 && (
            <div className="prescription-switch__container__step-three">
              <div className="prescription-switch__container__step-three__title">
                {currentSelectedTitle[currentSelected - 1]}
              </div>
              <div className="prescription-switch__container__step-three__form">
                <div className="prescription-switch__container__step-three__form__items">
                  <div className="prescription-switch__container__step-three__form__items__item">
                    <AdvancedInput
                      label={'Nome'}
                      onChange={(e) => handleChangeForm('name', e.target.value)}
                      value={form.name || loading > 0}
                    />
                  </div>
                  <div className="prescription-switch__container__step-three__form__items__item">
                    <AdvancedInput
                      label={'CPF'}
                      value={cpfMask(form.password)}
                      onChange={(e) =>
                        handleChangeForm('password', e.target.value)
                      }
                      disabled={me || loading > 0}
                    />
                  </div>
                </div>
                <div className="prescription-switch__container__step-three__form__items">
                  <div className="prescription-switch__container__step-three__form__items__item">
                    <AdvancedInput
                      label={'Telefone'}
                      value={phoneMask(form.phone)}
                      onChange={(e) =>
                        handleChangeForm('phone', phoneMask(e.target.value))
                      }
                    />
                  </div>
                  <div className="prescription-switch__container__step-three__form__items__item">
                    <AdvancedInput
                      name="birthDate"
                      label={'Nascimento'}
                      value={
                        form.birthDate ||
                        (me && moment(me.birthDate).format('DD/MM/YYYY'))
                      }
                      onChange={(e) =>
                        handleChangeForm('birthDate', e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="prescription-switch__container__step-three__form__items prescription-switch__container__step-three__form__items--single">
                  <AdvancedInput
                    label={'E-mail'}
                    value={form.email && form.email.toLowerCase()}
                    onChange={(e) => handleChangeForm('email', e.target.value)}
                    disabled={me || loading > 0}
                  />
                </div>
              </div>
              <div className="prescription-switch__container__step-three__policy">
                <div className="prescription-switch__container__step-three__policy__text">
                  {I18n.t('AVALIATION.TERMS.TERMS_ONE')}
                  <u>
                    <a
                      href="https://themens-terms.s3-sa-east-1.amazonaws.com/termos_geral.pdf"
                      // eslint-disable-next-line react/jsx-no-target-blank
                      target="_blank"
                      rel="noreferrer"
                    >
                      {' '}
                      {I18n.t('AVALIATION.TERMS.TERMS_TWO')}{' '}
                    </a>
                  </u>
                  {I18n.t('AVALIATION.TERMS.TERMS_THREE')}
                  <u>
                    <a
                      href="https://themens-terms.s3-sa-east-1.amazonaws.com/politica_privacidade.pdf"
                      // eslint-disable-next-line react/jsx-no-target-blank
                      target="_blank"
                      rel="noreferrer"
                    >
                      {' '}
                      {I18n.t('AVALIATION.TERMS.TERMS_FOUR')}{' '}
                    </a>
                  </u>
                  {I18n.t('AVALIATION.TERMS.TERMS_FIVE')}
                  <u onClick={() => setStep(4)}>
                    {' '}
                    {I18n.t('AVALIATION.TERMS.TERMS_SIX')}
                  </u>
                </div>
              </div>
              <div
                className="prescription-switch__container__step-three__button"
                onClick={() => onNextClick()}
              >
                {I18n.t('AVALIATION.CONTINUE')}
              </div>
            </div>
          )}
          {step === 4 && (
            <div className="prescription-switch__container__step-four">
              <div className="prescription-switch__container__step-four__item">
                • {I18n.t('AVALIATION.STEP.STEP_ONE')}
              </div>
              <div className="prescription-switch__container__step-four__item">
                • {I18n.t('AVALIATION.STEP.STEP_TWO')}
              </div>
              <div className="prescription-switch__container__step-four__item">
                • {I18n.t('AVALIATION.STEP.STEP_THREE')}
              </div>
              <div className="prescription-switch__container__step-four__item">
                • {I18n.t('AVALIATION.STEP.STEP_FOUR')}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  me: state.auth.me,
  loading: state.loading,
});

const mapDispatchToProps = (dispatch) => ({
  setQuizUserResponse: (payload) =>
    dispatch(PrescriptionActions.setQuizUserResponse(payload)),
  setQuizUserPayload: (payload) =>
    dispatch(PrescriptionActions.setQuizUserPayload(payload)),
  updateUserProfile: (id, data, callback) =>
    dispatch(UserActions.updateUser(id, data, callback)),
  login: (data, callback) =>
    dispatch(AuthActions.authenticateV2(data, callback)),
  register: (data, callback) => dispatch(UserActions.createV2(data, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  userActivity: (params, userActivity) =>
    dispatch(FacebookActions.userActivity(params, userActivity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionSwitch);
