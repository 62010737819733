import { useMemo } from 'react';
import { I18n } from 'react-redux-i18n';

export const useTextAndClassname = (prescriptionType) => {

  return useMemo(() => {
    switch (prescriptionType) {
      case 1:
        return {
          title: I18n.t('COMPONENTS.PRESCRIPTION.PRESCRIPTION_FEEDBACK.TITLES.MELATONIN'),
          classTitle: 'MELATONIN',
          colorIcon: '#8371B9',
          url: 'https://themens3.lojavirtualnuvem.com.br/comprar/10104659213-1'
        };
      case 2:
      case 4:
        return {
          title: I18n.t('COMPONENTS.PRESCRIPTION.PRESCRIPTION_FEEDBACK.TITLES.ED'),
          classTitle: 'ED',
          colorIcon: '#4C9C9C',
          url: 'https://themens3.lojavirtualnuvem.com.br/comprar/1023402213-1'
        };
      case 3:
        return {
          title: I18n.t('COMPONENTS.PRESCRIPTION.PRESCRIPTION_FEEDBACK.TITLES.HAIR_LOSS'),
          classTitle: 'HAIR_LOSS',
          colorIcon: '#6DA7CB',
          url: 'https://themens3.lojavirtualnuvem.com.br/comprar/1023380956-1,1010424294-1,1023393219-1,1010228371-1',
        };
      default:
        return {
          title: '',
          classTitle: '',
          colorIcon: '#1D6EA3',
          url: '',
        };
    }
  }, [ prescriptionType ]);
};